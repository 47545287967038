.sp-card {
  width: fit-content;
  max-width: 337px;
  justify-content: center;
  background-color: #0000005e;
  padding: 14px;
  border-radius: 20px;
}

.sp-card .sp-spin-container {
  align-self: center;
  padding-right: 10px;
}

.sp-card .sp-img-container {
  margin-right: 20px;
  height: 50px;
  max-height: 50px;
}

.sp-card .sp-img-container img {
  width: 50px;
}

.sp-card .sp-track {
  align-self: self-end;
  margin-right: auto;
}

.sp-card .sp-track h2 {
  font-size: 12px;
}

.sp-card .sp-track h3 {
  font-size: 10px;
}

/** Progress **/
.sp-progress-container {
  padding-top: 7px;
}

.sp-progress {
  border: 1px solid transparent;
  background-color: #535353;
  margin-top: 5px;
}

.sp-progress-bar {
  background-color: #b3b3b3;
  border: 1px solid transparent;
  height: 1px;
}

.sp-progress-time {
  font-size: 9px;
  align-self: center;
  width: 23px;
}

.sp-progress-time-right {
  position: absolute;
  right: 22px;
}

.sp-clickeable {
  text-decoration: none;
  color: #ccd6f6;
}

.sp-clickeable:link {
  text-decoration: none;
}

.sp-clickeable:visited {
  text-decoration: none;
}

.sp-clickeable:hover {
  text-decoration: none;
  color: white;
}

.sp-clickeable:active {
  text-decoration: none;
}
