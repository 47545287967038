.ci-cd-deploy .octicon {
  margin-right: 10px;
}

.ci-cd-deploy a {
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding-bottom: 10px;
  vertical-align: middle;
}

.ci-cd-deploy ul {
  overflow: hidden;
  list-style: none;
  color: #c6cdf0;
  padding-left: 30px;
  padding-top: 5px;
  list-style-type: none;
}

.ci-cd-deploy li {
  font-size: 14px;
  margin-bottom: 10px;
}

.ci-cd-deploy li::before {
  content: "·";
  position: absolute;
  left: 30px;
  color: #d100ff;
}

.ci-cd-deploy .job-time {
  font-size: 10px;
  vertical-align: middle;
  color: #d100ff;
}

.ci-cd-deploy .more-commits {
  color: #ccd6f6;
  font-size: 13px;
  padding-left: 15px;
  font-weight: normal;
}

.ci-cd-deploy .text-commit span {
  font-size: 12px;
}
