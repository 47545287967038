.presentacion {
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.75) 100%, rgba(0, 0, 0, 0.5) 100%),
    url("../../assets/europa.png");
  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;
  width: 100%;

  text-align: center;

  padding-top: 90px;
  padding-bottom: 90px;
  max-width: -1px;
}

.presentacion span {
  font-size: 18px;
  font-family: "Calibre", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

.contact-list {
  justify-content: center;
  font-size: 18px;
}

.circle {
  border-radius: 76px;
}

.octicon-mark-github:hover {
  fill: white;
}

.octicon-mark-github:hover {
  fill: white;
}

.octicon-mail:hover {
  fill: #dc190a;
}

.fa-linkedin:hover {
  color: #0077b0 !important;
}

.fa-twitter:hover {
  color: #1c9cea !important;
}

.fa-instagram:hover {
  color: #f700d9 !important;
}

.fa-facebook:hover {
  color: #1a73ea !important;
}

.banderas {
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.bandera {
  max-width: 100px;
}

.bandera img {
  width: 32px;
}

.bandera legend {
  font-size: 12px;
}

.bandera :hover,
.bandera img:hover {
  color: #febaff;
  cursor: pointer;
}

.btn-download-cv {
  background-color: transparent;
  border: 1px solid #d100ff;
  padding-block: 5px;
  padding-inline: 20px;
  border-radius: 10px;
  color: #f3beff;
}

.btn-download-cv:hover {
  color: white;
  cursor: pointer;
}
