body {
  background-color: #0a192f;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.app-container {
  max-width: 1200px;
  text-align: left;
}
