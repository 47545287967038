.card-codigo {
  padding-bottom: 30px;
}

.ci-cd-repo a {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.ci-cd-repo span {
  font-size: 18px;
  font-weight: bold;
  color: #d100ff;
}

.ci-cd-repo ul {
  overflow: hidden;
  list-style: none;
  color: #c6cdf0;
  padding-left: 30px;
  padding-top: 5px;
}

.ci-cd-repo li::before {
  content: "-";
  position: absolute;
  left: 30px;
  color: #d100ff;
}

.ci-cd-repo .commit-info {
  align-items: center;
  align-self: center;
  align-content: center;
  vertical-align: middle;
  margin-bottom: 15px;
}

.ci-cd-repo .commit-author {
  color: #7a81a2;
  font-size: 10px;
  font-weight: normal;
  padding-left: 5px;
}

.ci-cd-repo .commit-date {
  color: #7a81a2;
  font-size: 10px;
  font-weight: normal;
  padding-left: 3px;
}

.ci-cd-repo .more-commits {
  color: #ccd6f6;
  font-size: 13px;
  padding-left: 15px;
  font-weight: normal;
}

.ci-cd-repo .text-commit a {
  font-size: 13px;
  font-weight: normal;
  color: #c6cdf0;
}
