.aboutme {
  padding-top: 100px;
}

h1 {
  padding-top: 40px;
  padding-left: 15px;
  color: #ccd6f6;
}

span {
  color: #ccd6f6;
  text-align: center;
}

.aboutme .numbered-heading {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.aboutme .numbered-heading::after {
  content: "";
  display: block;
  position: relative;
  top: 2px;
  width: 100%;
  height: 1px;
  margin-left: 20px;
  background-color: #d100ff;
}

.img-container {
  display: flex;
  justify-content: center;
  align-self: center !important;
  text-align: center !important;
  position: relative;
  top: 45px;
  max-height: 500px;
}

.img-container .img-wrapper {
  position: relative;
}

.img-container .img-animation-fadein {
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

.img-container .img-animation-fadeout {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

.img-container img {
  width: 100%;
  border-radius: 20px;
  margin-top: -7px;
  max-width: 400px;
  max-height: 500px;
}

.img-container .img-border {
  border: 1px solid #d100ff;
  display: block;
  border-radius: 20px;
  position: absolute;
  top: 4px;
  left: -4px;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 0px;
  margin: 0px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  97% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
