.resultado {
  /*max-width: 250px;*/
  width: max-content;
  max-height: 78px;
  justify-content: left;
  background-color: #0000005e;
  padding: 14px;
  padding-left: 5px;
  border-radius: 10px;
}

.res-clickeable {
  cursor: pointer;
}

.res-clickeable:hover {
  background-color: #000e006b;
}

.resultado .icon-add {
  color: green;
  align-self: center;
  font-size: 30px;
  margin-right: 5px;
}

.resultado .buscador-track {
  align-self: self-end;
}

.resultado .buscador-track h2 {
  font-size: 12px;
}

.resultado .buscador-track h3 {
  font-size: 10px;
}

.resultado .buscador-img-container {
  margin-right: 20px;
}

.resultado .buscador-img-container img {
  width: 50px;
}

.resultado .resultado-loading {
  color: gray;
  height: 78px;
  padding-top: 14px;
  padding-left: 37px;
  padding-right: 13px;
}
