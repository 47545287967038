.buscador {
  position: absolute;
}

.buscador input {
  background-color: #ffffff1f;
  border-width: 0px;
  margin: 10px;
  margin-top: 0px;
  padding: 7px;
  padding-left: 12px;
  width: 200px;
  max-width: 200px;
  border-radius: 10px;
  color: white;
  font-size: 14px;
}

.icon-close {
  color: red;
  position: absolute;
  margin-top: 9px;
  cursor: pointer;
  margin-left: -36px;
}

.buscador-container {
  justify-content: center;
  height: 36px;
  width: 439px;
}
