.ci-cd-tests .octicon {
  margin-right: 10px;
}

.ci-cd-tests a {
  font-size: 17px;
  font-weight: bold;
  color: white;
  padding-bottom: 10px;
  vertical-align: middle;
}

.ci-cd-tests ul {
  overflow: hidden;
  list-style: none;
  color: #c6cdf0;
  padding-left: 30px;
  padding-top: 5px;
  list-style-type: none;
}

.ci-cd-tests li {
  font-size: 13px;
  margin-bottom: 14px;
}

.ci-cd-tests .job-time {
  font-size: 10px;
  vertical-align: middle;
  color: #d100ff;
}
